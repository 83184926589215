import { handleApi } from '@/utils/handleApi'

export const actions = {
  createOrder(context, params) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.post('/quick-orders', params), context)
    })
  },
  getOrderInfo(context, params) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get('/quick-orders/' + params.code + '/info'), context)
    })
  },
  updateOrder(context, params) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.post('/quick-orders/' + params.code + '/update', params), context)
    })
  }
}
