
export default {
  name: 'NotificationElement',
  props: ['text', 'classNotify', 'bIcon', 'close', 'type'],
  computed: {
    classNotification() {
      let classType = ''
      if (this.type === 'success') { classType = 'notification-element--success' } else if (this.type === 'danger') { classType = 'notification-element--danger' } else if (this.type === 'warning') { classType = 'notification-element--warning' }
      return classType
    }
  },
  methods: {
    closeNotify() {
      this.$emit('close')
    }
  }
}
