
import { CONTACT_DESIGN_CREATE, INDEX_SET_ERROR, INDEX_SET_LOADING, INDEX_SET_SUCCESS } from '../../store/store.const'
import { validPhone } from '@/utils/validate'

export default {
  name: 'ContactDesign',
  data() {
    const validPhoneNumber = (rule, value, callback) => {
      if (!validPhone(value)) {
        callback(new Error(this.$t('validation.phone')))
      } else {
        callback()
      }
    }
    return {
      contactDesignForm: {
        name: '',
        phone_number: '',
        content: '',
        error: {}
      },
      error: {
        key: null,
        value: ''
      },
      contactDesignRules: {
        name: [
          { required: true, message: this.$t('validation.required', { _field_: this.$t('contact_design.input.name') }), trigger: 'blur' }
        ],
        phone: [
          { required: true, message: this.$t('validation.required', { _field_: this.$t('contact_design.input.phone') }), trigger: 'blur' },
          { validator: validPhoneNumber, trigger: 'blur' }
        ],
        content: [
          { required: true, message: this.$t('validation.required', { _field_: this.$t('contact_design.input.content') }), trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    if (this.$refs.contactDesignForm !== undefined) {
      this.resetValidate('contactDesignForm')
    }
  },
  methods: {
    resetValidate(ref) {
      if (ref === this.error.key) {
        this.error = { key: null, value: '' }
      }
      this.$refs.contactDesignForm.fields.find((f) => f.prop === ref).clearValidate()
      this.contactDesignForm.error[ref] = ''
    },
    sendContactDesign() {
      this.error = { key: null, value: '' }
      this.$refs.contactDesignForm.validate(async valid => {
        if (valid) {
          try {
            await this.$store.commit(INDEX_SET_LOADING, true)
            const data = await this.$store.dispatch(CONTACT_DESIGN_CREATE, {
              name: this.contactDesignForm.name,
              phone: this.contactDesignForm.phone,
              content: this.contactDesignForm.content
            })

            switch (data.status_code) {
              case 200:
                await this.$store.commit(INDEX_SET_SUCCESS, {
                  show: true,
                  text: this.$t('contact_design.created_success')
                })
                this.contactDesignForm.name = ''
                this.contactDesignForm.phone = ''
                this.contactDesignForm.content = ''
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
                break
              case 422:
                for (const [key] of Object.entries(data.data)) {
                  this.error = { key, value: data.data[key][0] }
                }
                break
              default:
                await this.$store.commit(INDEX_SET_ERROR, { show: true, text: data.message })
                break
            }
          } catch (err) {
            await this.$store.commit(INDEX_SET_ERROR, { show: true, text: this.$t('message.message_error') })
          }
          await this.$store.commit(INDEX_SET_LOADING, false)
        }
      })
    }
  }

}
