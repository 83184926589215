import { handleApi } from '../utils/handleApi'
export const state = () => ({
})

export const mutations = {
}

export const actions = {
  getListCategoryBlogs(context) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get('/blog-categories/overview'), context)
    })
  },
  getListBlogs(context, params) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get('/blogs?' + new URLSearchParams(params.filters).toString()), context)
    })
  },
  getListBlogsTag(context, params) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get('/blogs/tag?' + new URLSearchParams(params.filters).toString()), context)
    })
  },
  detailBlog(context, params) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get('/blogs/' + params.slug), context)
    })
  },
  productsRecommend(context, params) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get('/blogs/' + params.id + '/blog-product'), context)
    })
  },
  randomBlog(context) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get('/blogs/random'), context)
    })
  },
  getTypicalBlog(context, params) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get('/home/featured-project', params), context)
    })
  }
}
