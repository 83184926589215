import axios from 'axios'

export default async function({ route, redirect }) {
  try {
    const excludedRoutes = ['/category/', '/product/', '/blogs/']

    if (excludedRoutes.some((excludedRoute) => route.path.includes(excludedRoute))) {
      return
    }
    const response = await axios.get(process.env.API_URL + '/check-redirect?redirect_from=' + route.path.slice(1))
    const redirectUrl = response.data.data.link
    if (redirectUrl) {
      return redirect(redirectUrl)
    }
  } catch (error) {
    console.error(error)
  }
}
