export const LoadingBar = () => import('../../components/LoadingBar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const BlockUiNotificationBlock = () => import('../../components/block-ui/NotificationBlock.vue' /* webpackChunkName: "components/block-ui-notification-block" */).then(c => wrapFunctional(c.default || c))
export const BlogAppendixBlog = () => import('../../components/blog/appendix_blog.vue' /* webpackChunkName: "components/blog-appendix-blog" */).then(c => wrapFunctional(c.default || c))
export const BlogSimpleProduct = () => import('../../components/blog/simple_product.vue' /* webpackChunkName: "components/blog-simple-product" */).then(c => wrapFunctional(c.default || c))
export const BlogSingle = () => import('../../components/blog/single.vue' /* webpackChunkName: "components/blog-single" */).then(c => wrapFunctional(c.default || c))
export const CategoryLevel1 = () => import('../../components/category/CategoryLevel1.vue' /* webpackChunkName: "components/category-level1" */).then(c => wrapFunctional(c.default || c))
export const CategoryLevel3 = () => import('../../components/category/CategoryLevel3.vue' /* webpackChunkName: "components/category-level3" */).then(c => wrapFunctional(c.default || c))
export const CheckoutConfirm = () => import('../../components/checkout/confirm.vue' /* webpackChunkName: "components/checkout-confirm" */).then(c => wrapFunctional(c.default || c))
export const CollectionCrsMenuCollection = () => import('../../components/collection/CrsMenuCollection.vue' /* webpackChunkName: "components/collection-crs-menu-collection" */).then(c => wrapFunctional(c.default || c))
export const CollectionDescCollection = () => import('../../components/collection/DescCollection.vue' /* webpackChunkName: "components/collection-desc-collection" */).then(c => wrapFunctional(c.default || c))
export const CollectionMarkingProduct = () => import('../../components/collection/MarkingProduct.vue' /* webpackChunkName: "components/collection-marking-product" */).then(c => wrapFunctional(c.default || c))
export const CollectionMoreProduct = () => import('../../components/collection/MoreProduct.vue' /* webpackChunkName: "components/collection-more-product" */).then(c => wrapFunctional(c.default || c))
export const CollectionProductCollection = () => import('../../components/collection/ProductCollection.vue' /* webpackChunkName: "components/collection-product-collection" */).then(c => wrapFunctional(c.default || c))
export const ElementUiForgotPasswordElement = () => import('../../components/element-ui/ForgotPasswordElement.vue' /* webpackChunkName: "components/element-ui-forgot-password-element" */).then(c => wrapFunctional(c.default || c))
export const ElementUiListFilter = () => import('../../components/element-ui/ListFilter.vue' /* webpackChunkName: "components/element-ui-list-filter" */).then(c => wrapFunctional(c.default || c))
export const ElementUiLoginElement = () => import('../../components/element-ui/LoginElement.vue' /* webpackChunkName: "components/element-ui-login-element" */).then(c => wrapFunctional(c.default || c))
export const ElementUiNotificationElement = () => import('../../components/element-ui/NotificationElement.vue' /* webpackChunkName: "components/element-ui-notification-element" */).then(c => wrapFunctional(c.default || c))
export const ElementUiPagination = () => import('../../components/element-ui/Pagination.vue' /* webpackChunkName: "components/element-ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const ElementUiRegisterElement = () => import('../../components/element-ui/RegisterElement.vue' /* webpackChunkName: "components/element-ui-register-element" */).then(c => wrapFunctional(c.default || c))
export const HomeCategoryHot = () => import('../../components/home/CategoryHot.vue' /* webpackChunkName: "components/home-category-hot" */).then(c => wrapFunctional(c.default || c))
export const HomeDealHot = () => import('../../components/home/DealHot.vue' /* webpackChunkName: "components/home-deal-hot" */).then(c => wrapFunctional(c.default || c))
export const HomeIntroVideo = () => import('../../components/home/IntroVideo.vue' /* webpackChunkName: "components/home-intro-video" */).then(c => wrapFunctional(c.default || c))
export const HomeLastSeen = () => import('../../components/home/LastSeen.vue' /* webpackChunkName: "components/home-last-seen" */).then(c => wrapFunctional(c.default || c))
export const HomeProductDaily = () => import('../../components/home/ProductDaily.vue' /* webpackChunkName: "components/home-product-daily" */).then(c => wrapFunctional(c.default || c))
export const HomeRegisterCommon = () => import('../../components/home/RegisterCommon.vue' /* webpackChunkName: "components/home-register-common" */).then(c => wrapFunctional(c.default || c))
export const HomeSellingProduct = () => import('../../components/home/SellingProduct.vue' /* webpackChunkName: "components/home-selling-product" */).then(c => wrapFunctional(c.default || c))
export const HomeServiceCommon = () => import('../../components/home/ServiceCommon.vue' /* webpackChunkName: "components/home-service-common" */).then(c => wrapFunctional(c.default || c))
export const HomeSildeShow = () => import('../../components/home/SildeShow.vue' /* webpackChunkName: "components/home-silde-show" */).then(c => wrapFunctional(c.default || c))
export const HomeStoreHot = () => import('../../components/home/StoreHot.vue' /* webpackChunkName: "components/home-store-hot" */).then(c => wrapFunctional(c.default || c))
export const HomeStoreInfo = () => import('../../components/home/StoreInfo.vue' /* webpackChunkName: "components/home-store-info" */).then(c => wrapFunctional(c.default || c))
export const HomeTypicalProject = () => import('../../components/home/TypicalProject.vue' /* webpackChunkName: "components/home-typical-project" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterCommon = () => import('../../components/layout/FooterCommon.vue' /* webpackChunkName: "components/layout-footer-common" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterMobile = () => import('../../components/layout/FooterMobile.vue' /* webpackChunkName: "components/layout-footer-mobile" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderCommon = () => import('../../components/layout/HeaderCommon.vue' /* webpackChunkName: "components/layout-header-common" */).then(c => wrapFunctional(c.default || c))
export const LayoutListMenu = () => import('../../components/layout/ListMenu.vue' /* webpackChunkName: "components/layout-list-menu" */).then(c => wrapFunctional(c.default || c))
export const MauThietKeContactDesign = () => import('../../components/mau-thiet-ke/ContactDesign.vue' /* webpackChunkName: "components/mau-thiet-ke-contact-design" */).then(c => wrapFunctional(c.default || c))
export const ModalsModal = () => import('../../components/modals/modal.vue' /* webpackChunkName: "components/modals-modal" */).then(c => wrapFunctional(c.default || c))
export const OrderEvaluate = () => import('../../components/order/evaluate.vue' /* webpackChunkName: "components/order-evaluate" */).then(c => wrapFunctional(c.default || c))
export const OrderShowEvaluate = () => import('../../components/order/show_evaluate.vue' /* webpackChunkName: "components/order-show-evaluate" */).then(c => wrapFunctional(c.default || c))
export const OtpInput = () => import('../../components/otp/OtpInput.vue' /* webpackChunkName: "components/otp-input" */).then(c => wrapFunctional(c.default || c))
export const OtpSingleOtpInput = () => import('../../components/otp/SingleOtpInput.vue' /* webpackChunkName: "components/otp-single-otp-input" */).then(c => wrapFunctional(c.default || c))
export const ProductPolicyProduct = () => import('../../components/product/PolicyProduct.vue' /* webpackChunkName: "components/product-policy-product" */).then(c => wrapFunctional(c.default || c))
export const ProductSildeShow = () => import('../../components/product/SildeShow.vue' /* webpackChunkName: "components/product-silde-show" */).then(c => wrapFunctional(c.default || c))
export const ProductSimple = () => import('../../components/product/simple.vue' /* webpackChunkName: "components/product-simple" */).then(c => wrapFunctional(c.default || c))
export const ProductSingle = () => import('../../components/product/single.vue' /* webpackChunkName: "components/product-single" */).then(c => wrapFunctional(c.default || c))
export const ElementUiChatBox = () => import('../../components/element-ui/Chat/ChatBox.vue' /* webpackChunkName: "components/element-ui-chat-box" */).then(c => wrapFunctional(c.default || c))
export const ElementUiFilterBasic = () => import('../../components/element-ui/filter/FilterBasic.vue' /* webpackChunkName: "components/element-ui-filter-basic" */).then(c => wrapFunctional(c.default || c))
export const ElementUiFilterCategory = () => import('../../components/element-ui/filter/FilterCategory.vue' /* webpackChunkName: "components/element-ui-filter-category" */).then(c => wrapFunctional(c.default || c))
export const ElementUiFilterCategorySearch = () => import('../../components/element-ui/filter/FilterCategorySearch.vue' /* webpackChunkName: "components/element-ui-filter-category-search" */).then(c => wrapFunctional(c.default || c))
export const ElementUiFilterPrice = () => import('../../components/element-ui/filter/FilterPrice.vue' /* webpackChunkName: "components/element-ui-filter-price" */).then(c => wrapFunctional(c.default || c))
export const ElementUiFilterRating = () => import('../../components/element-ui/filter/FilterRating.vue' /* webpackChunkName: "components/element-ui-filter-rating" */).then(c => wrapFunctional(c.default || c))
export const ElementUiFilterSortCategory = () => import('../../components/element-ui/filter/SortCategory.vue' /* webpackChunkName: "components/element-ui-filter-sort-category" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
