
import { mapState } from 'vuex'
import vClickOutside from 'v-click-outside'
import HeaderCommon from '../components/layout/HeaderCommon'
import ListMenu from '../components/layout/ListMenu'
import FooterCommon from '../components/layout/FooterCommon'
import FooterMobile from '../components/layout/FooterMobile'
import LoadingBar from '../components/LoadingBar'
import NotificationBlock from '../components/block-ui/NotificationBlock'
import {
  MASTER_GET_DATA,
  AUTH_OPEN_MODAL_LOGIN,
  INDEX_SET_HIDE_FOOTER,
  GET_FLASH_SALE
} from '../store/store.const'
import { removeVietnameseTones, textToSlug } from '../utils/utils'
import ServiceCommon from '../components/home/ServiceCommon'
import RegisterCommon from '../components/home/RegisterCommon'
import StoreInfo from '../components/home/StoreInfo'
import ContactDesign from '../components/mau-thiet-ke/ContactDesign.vue'

export default {
  name: 'DefaultLayout',
  components: { FooterMobile, LoadingBar, FooterCommon, HeaderCommon, ListMenu, NotificationBlock, ServiceCommon, RegisterCommon, StoreInfo, ContactDesign },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      categories: [
        {
          name: '',
          link: '',
          active: true,
          children: []
        }
      ],
      windowWidth: 0,
      showPhoneAni: true,
      flash_sale: {},
      popupFlashSale: false,
      imageFlashSale: '',
      provider_url: '',
      supportExpand: false,
      supportExpandPhone: false
    }
  },
  async fetch() {
    await this.fetchDataCategory()
  },
  computed: {
    ...mapState('authentication', {
      openModalLoginState: state => state.openModalLoginState
    }),
    hideFooter() {
      return this.$store.state.hideFooter
    }
  },
  watch: {
    $route() {
      this.$store.commit(INDEX_SET_HIDE_FOOTER, false)
    },
    switchChat() {
      if (this.switchChat && !this.$auth.loggedIn) {
        this.showLoginBox()
      }
    }
  },
  async created() {
    try {
      const flash_sale = await this.$store.dispatch(GET_FLASH_SALE)
      this.flash_sale = flash_sale.data
    } catch (e) {
      console.error(e)
    }
  },
  mounted() {
    if (this.flash_sale && this.flash_sale.url) {
      const store_flash_sale = window.localStorage.getItem('flash-sale')
      if (JSON.stringify(this.flash_sale) === store_flash_sale) {
        const storage_num_visibled = window.localStorage.getItem('num-visibled')
        if (storage_num_visibled) {
          if (storage_num_visibled === '1') {
            this.popupFlashSale = true
            this.imageFlashSale = this.flash_sale.url
            if (this.flash_sale.provider_url) {
              this.provider_url = this.flash_sale.provider_url
            }
            const date = new Date()
            const newDate = this.addHours(date, this.flash_sale.loop_num)
            window.localStorage.setItem('last-visibled', `${newDate}`)
            window.localStorage.setItem('num-visibled', '2')
          }
          if (storage_num_visibled === '2') {
            const last_viewed = new Date(String(window.localStorage.getItem('last-visibled'))).getTime()
            const now = new Date().getTime()
            if (now <= last_viewed) {
              this.popupFlashSale = false
            }
            if (now > last_viewed) {
              this.popupFlashSale = true
              this.imageFlashSale = this.flash_sale.url
              if (this.flash_sale.provider_url) {
                this.provider_url = this.flash_sale.provider_url
              }
              window.localStorage.setItem('num-visibled', '1')
              window.localStorage.removeItem('last-visibled')
            }
          }
        } else {
          this.popupFlashSale = true
          this.imageFlashSale = this.flash_sale.url
          if (this.flash_sale.provider_url) {
            this.provider_url = this.flash_sale.provider_url
          }
          window.localStorage.setItem('num-visibled', '1')
        }
      } else {
        this.popupFlashSale = true
        this.imageFlashSale = this.flash_sale.url
        if (this.flash_sale.provider_url) {
          this.provider_url = this.flash_sale.provider_url
        }
        window.localStorage.setItem('num-visibled', '1')
        window.localStorage.setItem('flash-sale', JSON.stringify(this.flash_sale))
      }
    }
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    setTimeout(() => {
      this.showPhoneAni = false
    }, 30000)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    async showLoginBox() {
      await this.$store.commit(AUTH_OPEN_MODAL_LOGIN, !this.openModalLoginState)
    },

    async fetchDataCategory() {
      try {
        const category = await this.$store.dispatch(MASTER_GET_DATA, 'resources[categories]')
        this.categories = this.convertData(category.data.categories)
      } catch (e) {
        console.log(e)
      }
    },
    onResize(ev) {
      this.windowWidth = window.innerWidth
    },
    convertData(category) {
      // console.log(category)
      const list = []
      category.forEach(cat => {
        if (cat.children.length > 0) {
          cat.children = this.convertData(cat.children)
        }
        list.push({
          id: cat.id,
          name: cat.name,
          link: '/category/' + textToSlug(removeVietnameseTones(cat.name)),
          image: cat.image_url,
          image_thumb_url: cat.image_thumb_url,
          children: cat.children
        })
        if (cat.slug === 'thiet-bi-nha-bep') {
          list.push({
            id: 0,
            name: 'Mẫu thiết kế',
            link: '/mau-thiet-ke',
            image: cat.image_url,
            image_thumb_url: cat.image_thumb_url,
            children: []
          })
        }
      })
      return list
    },
    addHours(date, hours) {
      const dateCopy = new Date(date)
      dateCopy.setHours(dateCopy.getHours() + hours)
      return dateCopy
    }
  }
}
