
import {
  REGISTER_RECEIVE_EMAIL,
  INDEX_SET_ERROR,
  INDEX_SET_LOADING,
  INDEX_SET_SUCCESS
} from '../../store/store.const'
import { validEmail } from '@/utils/validate'

export default {
  name: 'RegisterCommon',
  data() {
    const validEmailRule = (rule, value, callback) => {
      if (value === '') callback()
      if (!validEmail(value)) {
        callback(new Error(this.$t('validation.email')))
      } else {
        callback()
      }
    }
    return {
      accountForm: {
        email: '',
        errors: {}
      },
      loading: false,
      fullscreenLoading: false,
      accountRules: {
        email: [
          { required: true, message: this.$t('validation.required', { _field_: this.$t('my_account.email') }), trigger: 'change' },
          { validator: validEmailRule, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    disabledButton() {
      return !validEmail(this.accountForm.email)
    }
  },
  methods: {
    resetValidate(ref) {
      this.$refs.accountForm.fields.find((f) => f.prop === ref).clearValidate()
      this.accountForm.errors[ref] = ''
    },
    registerEmail() {
      this.$refs.accountForm.validate(async valid => {
        if (valid) {
          try {
            await this.$store.commit(INDEX_SET_LOADING, true)
            const data = await this.$store.dispatch(REGISTER_RECEIVE_EMAIL, { email: this.accountForm.email })
            if (data.status_code === 200) {
              await this.$store.commit(INDEX_SET_SUCCESS, {
                show: true,
                text: data.message
              })
            } else {
              await this.$store.commit(INDEX_SET_ERROR, { show: true, text: data.message })
            }
          } catch (err) {
            await this.$store.commit(INDEX_SET_ERROR, { show: true, text: this.$t('message.message_error') })
          }
          await this.$store.commit(INDEX_SET_LOADING, false)
        }
      })
    }
  }
}
