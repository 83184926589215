
import { mapState } from 'vuex'
import ModalElement from '../modals/modal'
import LoginElement from '../element-ui/LoginElement'
import RegisterElement from '../element-ui/RegisterElement'
import ForgotPasswordElement from '../element-ui/ForgotPasswordElement'
import {
  INDEX_SET_LOADING,
  AUTH_LOGOUT,
  CART_COUNT,
  CART_SET_COUNT,
  CART_GET_CART,
  CHAT_SET_UN_READ_MESSAGE,
  CHAT_SET_SWITCH_CHAT
} from '@/store/store.const'
import { textToHref } from '@/utils/utils'
export default {
  name: 'HeaderCommon',
  components: { ForgotPasswordElement, ModalElement, LoginElement, RegisterElement },
  props: {
    listCategories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      keyword: '',
      showChildCategory: false,
      listChildCategory: [],
      showModalMobile: false,
      hideModalMobile: false,
      showModalCategory: false,
      hideModalCategory: false,
      showModal: false,
      hideModal: false,
      cartCount: 0,
      user: {},
      listMenu: [
        {
          key: 'account',
          title: this.$t('home.my_account'),
          image: '/assets/images/icons/user-avatar.svg',
          link: '/my_account'
        },
        {
          key: 'order',
          title: this.$t('home.my_order'),
          image: '/assets/images/icons/my-orders.svg',
          link: '/orders'
        },
        {
          key: 'list_seen',
          title: this.$t('home.list_seen'),
          image: '/assets/images/icons/clock.svg',
          link: '/product/old'
        },
        {
          key: 'list_like',
          title: this.$t('home.list_like'),
          image: '/assets/images/icons/favorite.svg',
          link: '/product/favorite'
        }
      ],
      modalLogin: false,
      modalState: 'login',
      titleRegister: this.$t('register_account'),
      titleForgot: this.$t('modal_login.send_forgot_password'),
      scrollStage: -1,
      oldScrollY: 0
    }
  },
  computed: {
    ...mapState('authentication', {
      resetCartState: state => state.resetCartState,
      openModalLoginState: state => state.openModalLoginState
    })
  },
  watch: {
    async openModalLoginState() {
      await this.openModalLogin()
    },
    async resetCartState() {
      await this.fetchCart()
    }
  },
  async created() {
    if (this.$auth.loggedIn) {
      await this.fetchData()
      await this.fetchCart()
    }
  },
  mounted() {
    this.action()
    window.onscroll = () => {
      this.scrollStage = window.scrollY < 100 ? -1 : window.scrollY - this.oldScrollY > 0 ? 1 : 0
      this.oldScrollY = window.scrollY
    }
  },
  methods: {
    closeModal() {
      this.showModal = false
      this.hideModal = false
    },
    closeModalCategory() {
      this.showModalCategory = false
    },
    closeModalMobile() {
      this.showModalMobile = false
    },
    showChildCate(value) {
      this.listChildCategory = value
      this.showChildCategory = true
    },
    closeChildCate() {
      this.listChildCategory = []
      this.showChildCategory = false
    },
    async changeToLinkCate(link) {
      await this.$router.push(link)
      this.closeModalCategory()
    },
    checkActiveMenu(link) {
      return link === this.$route.fullPath
    },
    async openModalLogin() {
      this.showModalMobile = false
      this.modalLogin = true
      this.changeModal('login')
      await this.closeModal()
    },
    changeModal(state) {
      this.modalState = state
      this.addHashToLocation(state)
    },
    changeTitleModal(title) {
      switch (title.state) {
        case 'register':
          this.titleRegister = title.value
          break
        case 'forgot':
          this.titleForgot = title.value
          break
      }
    },
    async closeModalLogin() {
      this.modalLogin = false
      await this.fetchCart()
      this.addHashToLocation('')
      this.$store.commit(CHAT_SET_SWITCH_CHAT, false)
    },
    async fetchCart() {
      const res = await this.$store.dispatch(CART_GET_CART)
      let count = 0
      if (res.data) {
        const shops = res.data
        shops.forEach((shop) => {
          count += shop.cart.length
        })
        this.cartCount = count
        await this.$store.commit(CART_SET_COUNT, count)
      }
    },
    searchKeyword() {
      const data = this.keyword
      this.keyword = ''
      this.$router.push({ path: '/search', query: { search: data }})
    },
    async changeToLink(link) {
      if (this.$auth.user) {
        await this.$router.push(link)
        await this.closeModal()
        await this.closeModalMobile()
      } else {
        await this.openModalLogin()
      }
    },
    async logout() {
      this.$store.commit(INDEX_SET_LOADING, true)
      await this.$auth.logout()
      try {
        await this.$store.dispatch(AUTH_LOGOUT)
        await this.fetchCart()
        await this.$store.commit(CHAT_SET_UN_READ_MESSAGE, '')
      } catch (e) {
      }
      await this.openModalLogin()
      this.$store.commit(INDEX_SET_LOADING, false)
    },
    async fetchData() {
      try {
        const cart = await this.$store.dispatch(CART_COUNT)
        await this.$store.commit(CART_SET_COUNT, cart.data.cart_count)
      } catch (e) {
        console.log(e)
      }
    },
    addHashToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path + (params ? textToHref(params) : '')
      )
    },
    action() {
      let modal = ''
      switch (this.$route.hash) {
        case '#login':
          modal = 'login'
          break
        case '#register':
          modal = 'register'
          break
        case '#forgot':
          modal = 'forgot'
          break
      }
      if (modal !== '') {
        this.openModalLogin()
        this.changeModal(modal)
      }
    },
    showCart() {
      if (this.$auth.loggedIn) {
        this.$router.push('/cart')
      } else {
        this.openModalLogin()
      }
    },
    redirectHome() {
      if (this.$route.path !== '/') {
        this.$router.push('/')
      } else {
        window.location.reload()
      }
    },
    redirectBlogs() {
      if (this.$route.path !== '/blogs') {
        this.$router.push('/blogs')
      } else {
        window.location.reload()
      }
    }
  }
}
