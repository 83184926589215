
import { GET_LIST_SHOWROOMS, INDEX_SET_LOADING } from '~/store/store.const'

export default {
  name: 'FooterCommon',
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
      listMenuLeft: [
        {
          key: 'category',
          title: this.$t('home.footer.list_product'),
          link: '/category/san-pham',
          rel: 'nofollow'
        },
        {
          key: 'contact_customer',
          title: this.$t('home.footer.contact_customer'),
          link: '/ho-tro-khach-hang',
          rel: 'nofollow'
        },
        {
          key: 'insurance',
          title: this.$t('home.footer.insurance'),
          link: '/chinh-sach-bao-hanh',
          rel: 'nofollow'
        },
        {
          key: 'contact',
          title: this.$t('home.footer.contact'),
          link: '/thong-tin-lien-he',
          rel: 'nofollow'
        },
        {
          key: 'refund',
          title: this.$t('home.footer.refund'),
          link: '/tra-hang-hoan-tien',
          rel: 'nofollow'
        },
        {
          key: 'remove',
          title: this.$t('home.footer.remove'),
          link: '/chinh-sach-van-chuyen',
          rel: 'nofollow'
        },
        {
          key: 'orders',
          title: this.$t('home.footer.orders'),
          link: '/huong-dan-dat-hang',
          rel: 'nofollow'
        },
        {
          key: 'createAccount',
          title: this.$t('home.footer.create_account'),
          link: '/huong-dan-tao-tai-khoan',
          rel: 'nofollow'
        },
        {
          key: 'pay',
          title: this.$t('home.footer.pay'),
          link: '/huong-dan-thanh-toan',
          rel: 'nofollow'
        },
        {
          key: 'store',
          title: this.$t('home.footer.store'),
          link: '/dang-ky-dai-ly',
          rel: 'nofollow'
        }
      ],
      listShowrooms: [],
      contact: {
        hotline: {
          text: '0826 818 805',
          value: '0826818805'
        },
        switchboard: {
          text: '1900 8141',
          value: '19008141'
        }
      }
    }
  },
  created() {
    this.fetchListShowroom()
  },
  methods: {
    async fetchListShowroom() {
      await this.$store.commit(INDEX_SET_LOADING, true)
      await this.$store.dispatch(GET_LIST_SHOWROOMS).then(response => {
        const { data, status_code } = response
        if (status_code === 200) {
          this.listShowrooms = data.data
        }
      })
      await this.$store.commit(INDEX_SET_LOADING, false)
    }
  }
}
