import { handleApi } from '../utils/handleApi'
export const state = () => ({
})

export const mutations = {
}

export const actions = {
  registerAgency(context, params) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.post('/agency', params), context)
    })
  },
  getListAgency(context, params) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get(`/agency/?per_page=${params.per_page}`), context)
    })
  }
}
