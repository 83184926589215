
export default {
  name: 'ModalElement',
  // eslint-disable-next-line vue/require-prop-types
  props: ['width', 'padding', 'classModal', 'height', 'noClose', 'id', 'showHeader', 'showModalState'],
  methods: {
    close() {
      this.$emit('close')
    },
    closeModal() {
      if (this.showModalState) {
        this.$emit('close')
      }
    }
  }
}
