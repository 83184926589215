
import img1 from '../../static/assets/images/collection/collection1-2023.webp'
import img2 from '../../static/assets/images/collection/collection2-2023.webp'
import img3 from '../../static/assets/images/collection/collection3-2023.webp'

export default {
  name: 'CarouselMenu',
  data() {
    return {
      carousel: [
        img1,
        img2,
        img3,
        img1,
        img2,
        img3,
        img1,
        img2,
        img3
      ]
    }
  }
}
