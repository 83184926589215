
export default {
  name: 'ServiceCommon',
  data() {
    return {
      listService: [
        {
          title: 'Thiết kế theo yêu cầu',
          link: '',
          detail: 'Thiết kế nhanh chóng theo ý tưởng sản phẩm của bạn',
          image: '/assets/images/009-price-tag 1.svg'
        },
        {
          title: 'Giao hàng nhanh chóng & An toàn',
          link: '',
          detail: 'Giao hàng nhanh chóng và an toàn với hàng nghìn mặt hàng',
          image: '/assets/images/006-delivery-truck 1.svg'
        },
        {
          title: 'Hoàn tiền sau 15 ngày',
          link: '',
          detail: 'Hoàn tiền sau 15 ngày kể từ ngày nhận hàng bất kể lý do gì',
          image: '/assets/images/008-guide-book 2.svg'
        },
        {
          title: 'Nhiều sự lựa chọn',
          link: '',
          detail: 'Tất cả mọi thứ về nhà cửa, nội thất',
          image: '/assets/images/003-cabinet 1.svg'
        }
      ]
    }
  }
}
