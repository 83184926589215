import { handleApi } from '../utils/handleApi'
export const state = () => ({
})

export const mutations = {
}

export const actions = {
  getListShowrooms(context) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get('/showrooms'), context)
    })
  }
}
