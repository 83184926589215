export default ({ $gtm, app }) => {
  $gtm.init(process.env.GTM_ID)

  // push route to datalayer
  app.router.afterEach((to, from) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'nuxtRoute',
      pageType: 'PageView',
      pageUrl: to.fullPath,
      pagePath: to.path,
      pageTitle: to.name,
      routeName: to.name,
      siteName: process.env.TITLE
    })
  })

  // setup gtm
  function getCookie(name) {
    const value = '; ' + document.cookie
    const parts = value.split('; ' + name + '=')
    if (parts.length === 2) return parts.pop().split(';').shift()
  }
  const cookieReferralSource = getCookie('referral_source')

  app.router.beforeEach((to, from, next) => {
    if (process.client) {
      const dataLayer = window.dataLayer || []
      dataLayer.push({
        event: 'gtm.js',
        'gtm.start': new Date().getTime()
      })
      dataLayer.push({
        event: 'referrer',
        referral_source: cookieReferralSource || document.referrer
      })
      next()
    }
  })
}
