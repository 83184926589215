const middleware = {}

middleware['has-permission'] = require('../middleware/has-permission.js')
middleware['has-permission'] = middleware['has-permission'].default || middleware['has-permission']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['permission'] = require('../middleware/permission.js')
middleware['permission'] = middleware['permission'].default || middleware['permission']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

export default middleware
