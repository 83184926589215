
import OtpInput from '../otp/OtpInput'
import {
  INDEX_SET_ERROR,
  INDEX_SET_LOADING,
  INDEX_SET_SUCCESS,
  AUTH_SEND_OTP,
  AUTH_VERIFY_OTP,
  AUTH_REGISTER,
  AUTH_RE_SEND_OTP
} from '../../store/store.const'
import { validPhone } from '@/utils/validate'
export default {
  name: 'RegisterElement',
  components: { OtpInput },
  data() {
    const validPhoneNumber = (rule, value, callback) => {
      if (!validPhone(value)) {
        callback(new Error(this.$t('validation.phone')))
      } else {
        callback()
      }
    }
    const validPasswordConfirm = (rule, value, callback) => {
      if (value !== this.accountForm.password) {
        callback(new Error(this.$t('validation.confirm-password')))
      } else {
        callback()
      }
    }
    return {
      accountForm: {
        phone: '',
        name: '',
        password: '',
        password_confirm: '',
        token: '',
        errors: {}
      },
      passwordTypeReNew: 'password',
      passwordTypeNew: 'password',
      accountRules: {
        phone: [
          { required: true, message: this.$t('validation.required', { _field_: this.$t('modal_login.phone') }), trigger: 'blur' },
          { validator: validPhoneNumber, trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('validation.required', { _field_: this.$t('modal_login.name') }), trigger: 'blur' },
          { type: 'string', min: 5, message: this.$t('validation.min', { _field_: this.$t('modal_login.name'), _length_: 5 }), trigger: 'blur' },
          { type: 'string', max: 60, message: this.$t('validation.max', { _field_: this.$t('modal_login.name'), _length_: 60 }), trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('validation.required', { _field_: this.$t('modal_login.password') }), trigger: 'blur' },
          { type: 'string', min: 8, message: this.$t('validation.min', { _field_: this.$t('modal_login.password'), _length_: 8 }), trigger: 'blur' },
          { type: 'string', max: 32, message: this.$t('validation.max', { _field_: this.$t('modal_login.password'), _length_: 32 }), trigger: 'blur' }
        ],
        password_confirm: [
          { required: true, message: this.$t('validation.required', { _field_: this.$t('modal_login.password') }), trigger: 'blur' },
          { validator: validPasswordConfirm, trigger: 'blur' }
        ],
        remember: []
      },
      validation: {
        phone: false
      },
      capsToolPasswordTip: false,
      loading: false,
      fullscreenLoading: false,
      step: 1,
      configTitleRegister: {
        1: this.$t('register_account'),
        2: this.$t('modal_login.create_account'),
        3: this.$t('modal_login.create_account')
      },
      otpVerifyCode: '',
      timerCount: 0,
      idCount: null
    }
  },
  computed: {
    disabledButtonStep1() {
      if (!validPhone(this.accountForm.phone)) {
        return true
      }
      return false
    },
    disabledButtonStep2() {
      if (this.otpVerifyCode.length !== 6) {
        return true
      }
      return false
    },
    disabledButtonStep3() {
      if ((this.accountForm.name.length >= 5) && (this.accountForm.password.length >= 6) &&
        (this.accountForm.password_confirm === this.accountForm.password)) {
        return false
      }
      return true
    }
  },
  watch: {
    step() {
      this.$emit('changeTitle', { state: 'register', value: this.configTitleRegister[this.step] })
    },
    timerCount: {
      handler(value) {
        if (value > 0) {
          this.idCount = setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
      },
      immediate: true
    }
  },
  created() {
    if (this.$refs.accountFormStep1 !== undefined) {
      this.resetValidate('accountFormStep1')
    }
  },
  methods: {
    handleOnChangeOtp() {
      this.otpVerifyCode = ''
    },

    handleOnCompleteOtp(value) {
      this.otpVerifyCode = value
    },

    resetValidate(ref) {
      this.$refs['accountFormStep' + this.step].fields.find((f) => f.prop === ref).clearValidate()
      this.accountForm.errors[ref] = ''
    },
    checkCapslock(e, attr) {
      const { key } = e
      this[attr] = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    sendOtp() {
      if (!this.disabledButtonStep1) {
        this.$refs.accountFormStep1.validate(async valid => {
          if (valid) {
            try {
              await this.$store.commit(INDEX_SET_LOADING, true)
              const data = await this.$store.dispatch(AUTH_SEND_OTP, { phone_number: this.accountForm.phone, type: 1 })
              if (data.status_code === 200) {
                await this.$store.commit(INDEX_SET_SUCCESS, {
                  show: true,
                  text: data.message
                })
                this.step = 2
                this.timerCount = 60
              } else {
                await this.$store.commit(INDEX_SET_ERROR, { show: true, text: data.message })
              }
            } catch (err) {
              await this.$store.commit(INDEX_SET_ERROR, { show: true, text: this.$t('message.message_error') })
            }
            await this.$store.commit(INDEX_SET_LOADING, false)
          }
        })
      }
    },
    async reSendOtp() {
      try {
        await this.$store.commit(INDEX_SET_LOADING, true)
        const data = await this.$store.dispatch(AUTH_RE_SEND_OTP, { phone_number: this.accountForm.phone, type: 3 })
        if (data.status_code === 200) {
          await this.$store.commit(INDEX_SET_SUCCESS, {
            show: true,
            text: data.message
          })
          this.timerCount = 60
        } else {
          await this.$store.commit(INDEX_SET_ERROR, { show: true, text: data.message })
        }
      } catch (err) {
        await this.$store.commit(INDEX_SET_ERROR, { show: true, text: this.$t('message.message_error') })
      }
      await this.$store.commit(INDEX_SET_LOADING, false)
    },
    onlyNumber($event, index) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 48 || keyCode > 57)) {
        $event.preventDefault()
      } else {
        this.$refs.optCode[index < 5 ? index + 1 : 5 ].focus()
      }
    },
    confirmOtp() {
      if (!this.disabledButtonStep2) {
        this.$refs.accountFormStep2.validate(async valid => {
          if (valid) {
            try {
              await this.$store.commit(INDEX_SET_LOADING, true)
              const data = await this.$store.dispatch(AUTH_VERIFY_OTP, { phone_number: this.accountForm.phone, code_otp: this.otpVerifyCode })
              if (data.status_code === 200) {
                await this.$store.commit(INDEX_SET_SUCCESS, {
                  show: true,
                  text: data.message
                })
                this.accountForm.token = data.data.token
                this.step = 3
              } else {
                await this.$store.commit(INDEX_SET_ERROR, { show: true, text: data.message })
              }
            } catch (err) {
              await this.$store.commit(INDEX_SET_ERROR, { show: true, text: this.$t('message.message_error') })
            }
            await this.$store.commit(INDEX_SET_LOADING, false)
          }
        })
      }
    },
    register() {
      if (!this.disabledButtonStep3) {
        this.$refs.accountFormStep3.validate(async valid => {
          if (valid) {
            try {
              await this.$store.commit(INDEX_SET_LOADING, true)
              const data = await this.$store.dispatch(AUTH_REGISTER,
                {
                  name: this.accountForm.name,
                  password: this.accountForm.password,
                  password_confirmation: this.accountForm.password_confirm,
                  token: this.accountForm.token
                }
              )
              if (data.status_code === 200) {
                await this.$store.commit(INDEX_SET_SUCCESS, {
                  show: true,
                  text: data.message
                })
                await this.$store.commit(INDEX_SET_LOADING, false)
                await this.$emit('change', 'login')
              } else {
                await this.$store.commit(INDEX_SET_ERROR, { show: true, text: data.message })
              }
            } catch (err) {
              await this.$store.commit(INDEX_SET_ERROR, { show: true, text: this.$t('message.message_error') })
            }
            await this.$store.commit(INDEX_SET_LOADING, false)
          }
        })
      }
    },
    async changeModal(state) {
      await this.$emit('change', state)
    },
    changeStep(step) {
      if (this.step === 2) {
        clearInterval(this.idCount)
      }
      this.step = step
    },
    formatPhone(phone_number) {
      return '(+84)' + phone_number.slice(1, (phone_number.length))
    },
    checkValidate(value, status, message) {
      this.validation[value] = status
    },
    showPwd(attr) {
      if (this[attr] === 'password') {
        this[attr] = ''
      } else {
        this[attr] = 'password'
      }
    }
  }
}
