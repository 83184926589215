import { handleApi } from '~/utils/handleApi'

export const state = () => ({})

export const mutations = {}

export const actions = {
  getThemeDesigns(context, query) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get('theme_designs', { params: query }), context)
    })
  },

  getDetailThemeDesign(context, slug) {
    return new Promise((resolve, reject) => {
      handleApi(resolve, reject, this.$axios.get(`theme_designs/${slug}`), context)
    })
  }
}
