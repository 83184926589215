
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import CrsMenuCollection from '../../components/collection/CrsMenuCollection.vue'
// import _ from 'lodash'
export default {
  name: 'ListMenu',
  components: { VueSlickCarousel, CrsMenuCollection },
  // eslint-disable-next-line vue/require-prop-types
  props: ['listMenu'],
  data() {
    return {
      childContent: '',
      slideSetting: {
        'dots': false,
        'focusOnSelect': false,
        'infinite': false,
        'initialSlide': 0,
        'speed': 500,
        'slidesToShow': 6,
        'slidesToScroll': 1,
        'swipeToSlide': true,
        'responsive': [
          {
            'breakpoint': 992,
            'settings': {
              'slidesToShow': 5
            }
          }
        ]
      },
      slideSettingTab: {
        'dots': false,
        'focusOnSelect': false,
        'infinite': false,
        'initialSlide': 0,
        'speed': 500,
        'slidesToShow': 4,
        'slidesToScroll': 1,
        'swipeToSlide': true
      },
      showModal: false,
      showCollection: false,
      showActive: null,
      listMenuAdd: [
        { name: 'Tin tức', link: '/blogs' },
        { name: 'Giới thiệu', link: '/gioi-thieu' },
        { name: 'Liên hệ', link: '/thong-tin-lien-he' }
      ]
    }
  },

  methods: {
    showMenu() {
      this.showModal = true
    },
    hideMenu() {
      this.showModal = false
      this.showActive = null
    },
    changeToLink(link) {
      this.$router.push(link)
    },
    getVal(val) {
      this.childContent = val.children
      if (val.name === 'Bộ Tủ Lavabo') {
        this.showCollection = true
      } else {
        this.showCollection = false
      }
    },
    getResetChildContent(index) {
      this.childContent = this.listMenu[index]?.children[0]?.children
      if (this.listMenu[index].name === 'Sản phẩm') {
        this.showCollection = true
      } else {
        this.showCollection = false
      }
    }
  }
}
