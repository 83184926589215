
export default {
  name: 'StoreInfo',
  data() {
    return {
      isShowDetail: false
    }
  },
  methods: {
    showDetail() {
      this.isShowDetail = !this.isShowDetail
    }
  }
}
