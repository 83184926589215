
import { mapState } from 'vuex'

export default {
  data: () => ({}),
  computed: {
    ...mapState(['loading'])
  },
  methods: {}
}
